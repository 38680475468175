import ExperienceCounter from "../counter/ExperienceCounter";
type Props = {
  style: string;
  showHeader: boolean;
};
const AboutSection = ({ style, showHeader }: Props) => {
  return (
    <div className="container">
      <section className={`nk-about ${style}`} id="about">
        {showHeader && (
          <div className="nk-section__heading">
            <h6 className="nk-section__sub-title text-animate">About Me</h6>
            <h2 className="nk-section__title text-animate">
            Great tech starts with a vision, succeeds with execution.
            </h2>
          </div>
        )}

        <div className="nk-about-txt">
          <div className="nk-about-txt__left">
            <h3 className="nk-about-txt__title text-animate">
              Hello, I am Surya Prakash
            </h3>

            <p className="nk-about-txt__descr">With over 14 years of experience in technology leadership, I am dedicated to driving innovation and aligning technology strategies with overarching business objectives.</p>
            <p className="nk-about-txt__descr">My expertise includes secure and scalable deployments, digital transformation, cloud architecture, and a broad range of technology challenges.</p>
            <p className="nk-about-txt__descr">As a versatile professional, I focus on ensuring technology investments deliver maximum value and fostering high-performing teams.</p>

            <p className="nk-about-txt__descr">I have extensive experience in various domains, including FinTech, Digital Advertising with a focus on Reporting and Analytics, Real Estate and Mortgage markets in Dubai, eCommerce, Ware Housing, and handling banking software platforms at a very large scale with zero downtime.</p>
            <p className="nk-about-txt__descr">My background also includes e-Commerce, Warehouse Management, Application Deployment, and the Software Development Ecosystem.</p>
            <div className="nk-about-txt__actions">
              <a href="#contact" className="nk-def-btn">
                Conatct Me
              </a>
              <div className="nk-socials">
                <a href="https://x.com/SuryaElite"  target="_blank">X</a>
                <a href="https://www.linkedin.com/in/suryaelite/" target="_blank">LI</a>
              </div>
            </div>
          </div>

          <div className="nk-about-txt__right">
            <ExperienceCounter />
          </div>
        </div>

        <div className="nk-about__infos">
          <div className="row justify-content-center gy-4 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 row-cols-xxs-1">
            <div className="col">
              <div className="nk-about-info">
                <h5 className="nk-about-info__title">Name :</h5>
                <p className="nk-about-info__value">Surya Prakash</p>
              </div>
            </div>
            <div className="col">
              <div className="nk-about-info">
                <h5 className="nk-about-info__title">Email :</h5>
                <p className="nk-about-info__value">surya@suryaelite.com</p>
              </div>
            </div>
            <div className="col">
              <div className="nk-about-info">
                <h5 className="nk-about-info__title">Phone :</h5>
                <p className="nk-about-info__value">(+971) 54 501 4005</p>
              </div>
            </div>
            <div className="col">
              <div className="nk-about-info">
                <h5 className="nk-about-info__title">Skype :</h5>
                <p className="nk-about-info__value">suryaelite</p>
              </div>
            </div>
            <div className="col">
              <div className="nk-about-info">
                <h5 className="nk-about-info__title">Address :</h5>
                <p className="nk-about-info__value">Dubai, UAE</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSection;
