import ExperienceAndEducation from "./ExperienceAndEducation";
type Props = {
  style: string;
  showHeader: boolean;
};
const ExperienceSection = ({ style, showHeader }: Props) => {
  return (
    <section className={`${style} nk-section-spacing`}>
      <div className="container">
        {showHeader && (
          <div className="nk-section__heading">
            <h6 className="nk-section__sub-title text-animate">
              Experience And Education
            </h6>
            <h2 className="nk-section__title text-animate">
            I craft pioneering digital experiences for tech-driven brands.
            </h2>
          </div>
        )}
        <ExperienceAndEducation />
      </div>
    </section>
  );
};

export default ExperienceSection;
