import './assets/css/style.css';
import './assets/css/dark.css';
import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome.css';

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/store.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
