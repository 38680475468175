import BreadcrumbSection from "../component/breadcrumb/BreadcrumbSection";
import ContactSection from "../component/contact/ContactSection";

const ContactPage = () => {
  return (
    <main>
      <BreadcrumbSection currentPage="Contact" />
      <ContactSection style="" innerPage />
    </main>
  );
};

export default ContactPage;
