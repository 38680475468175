import { educationData, experienceData } from "../../data/Data";

const ExperienceAndEducation = () => {
  return (
    <div className="row gy-4 nk-experiences-row">
      <div className="col-sm-6">
        <div className="nk-infos">
          <h4 className="nk-infos__title text-animate">My Education</h4>
          {educationData.map((item) => (
            <div className="nk-info nk-skill" key={item.id}>
              <p className="nk-info__timespan">{item.timespan}</p>
              <div className="nk-info__details">
                <h5 className="nk-info__title">{item.degree}</h5>
                <h6 className="nk-info__sub-title">{item.school}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="col-sm-6">
        <div className="nk-infos">
          <h4 className="nk-infos__title text-animate">My Experiance</h4>

          {experienceData.map((item) => (
            <div className="nk-info nk-skill" key={item.id}>
              <p className="nk-info__timespan">{item.timespan}</p>
              <div className="nk-info__details">
                <h5 className="nk-info__title">{item.position}</h5>

                <h6 className="nk-info__sub-title">{item.company}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExperienceAndEducation;
