import { Link } from "react-router-dom";
type Props = {
  currentPage: string;
};
const BreadcrumbSection = ({ currentPage }: Props) => {
  return (
    <section className="nk-inner-banner">
      <div className="container">
        <h1 className="nk-inner-banner__title nk-section__title text-animate">
          {currentPage}
        </h1>

        <ul className="nk-breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <span className="divider"> / </span>
          </li>
          <li className="current">{currentPage}</li>
        </ul>
      </div>
    </section>
  );
};

export default BreadcrumbSection;
