import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleTheme } from "../../redux/features/themeToggleSlice";

const ThemeToggler = () => {
  const dispatch = useAppDispatch();
  const isThemeDark = useAppSelector((state) => state.themeToggle.isThemeDark);
  
  const handleToggle = () => {
    dispatch(toggleTheme());
  };

  // Apply dark mode to the document body
  useEffect(() => {
    document.body.classList.toggle("dark-mode", isThemeDark);
  }, [isThemeDark]);

  return (
    <button
      className={`dark-mode-toggle ${isThemeDark ? "dark" : "light"}`}
      onClick={handleToggle}
      aria-label={isThemeDark ? "Switch to light mode" : "Switch to dark mode"}
    >
      {isThemeDark ? (
        <i className="fa-regular fa-sun-bright"></i>
      ) : (
        <i className="fa-regular fa-moon"></i>
      )}
    </button>
  );
};

export default ThemeToggler;
