import BreadcrumbSection from "../component/breadcrumb/BreadcrumbSection";
import AboutSection from "../component/about/AboutSection";
import ExperienceSection from "../component/experience/ExperienceSection";
import ContactSection from "../component/contact/ContactSection";

const AboutPage = () => {
  return (
    <main>
      <BreadcrumbSection currentPage="About" />
      <AboutSection style="nk-about--inner" showHeader={false} />
      <ExperienceSection style="nk-inner-experiences" showHeader={false} />
      <ContactSection style="nk-inner-contact" />
    </main>
  );
};

export default AboutPage;
