import CustomCursor from "../utils/CustomCursor";
import HeaderSection from "../header/HeaderSection";
import FooterSection from "../footer/FooterSection";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <CustomCursor />
      <HeaderSection />
      <Outlet />
      <FooterSection />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Layout;
