import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NavigationMenu = () => {
  const [tabMode, setTabMode] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setTabMode(window.innerWidth < 992);
    }
  }, []);
  return (
    <div className="nk-header__nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        {tabMode && (
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NavigationMenu;
