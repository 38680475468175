const serviceData = [
  {
    id: 0,
    title: "Illustration Design",
    slug: "illustration-design",
    category: "Designer",
    imgSrc: "/assets/img/portfolio-1.jpg",
  },
  {
    id: 1,
    title: "Business Branding",
    slug: "business-branding",
    category: "Branding",
    imgSrc: "/assets/img/portfolio-2.jpg",
  },
  {
    id: 2,
    title: "Packaging Design",
    slug: "packaging-design",
    category: "Designer System",
    imgSrc: "/assets/img/portfolio-3.jpg",
  },
  {
    id: 3,
    title: "Mobile Apps Design",
    slug: "mobile-apps-design",
    category: "Branding",
    imgSrc: "/assets/img/portfolio-4.jpg",
  },
  {
    id: 4,
    title: "Web UI/UX Design",
    slug: "web-ui-ux-design",
    category: "UI/UX Design",
    imgSrc: "/assets/img/portfolio-5.jpg",
  },
  {
    id: 5,
    title: "Application Design",
    slug: "application-design",
    category: "Web Design",
    imgSrc: "/assets/img/portfolio-6.jpg",
  },
];

const testimonialData = [
  {
    id: 1,
    testimonial:
      "Duis aute irure dolor in reprehenderit in voluptate velit cillum dolore eu fugiat nulla pariatur. Excepteur sint proident, culpa qui officia deserunt mollit anim id est laborum.",
    imgSrc: "assets/img/reviewer-1.jpg",
    name: "Selena Brook",
    label: "Vivaco Group",
  },
  {
    id: 2,
    testimonial:
      "Duis aute irure dolor in reprehenderit in voluptate velit cillum dolore eu fugiat nulla pariatur. Excepteur sint proident, culpa qui officia deserunt mollit anim id est laborum.",
    imgSrc: "assets/img/reviewer-2.jpg",
    name: "Nicolas Marko",
    label: "Chief Officer",
  },
  {
    id: 3,
    testimonial:
      "Duis aute irure dolor in reprehenderit in voluptate velit cillum dolore eu fugiat nulla pariatur. Excepteur sint proident, culpa qui officia deserunt mollit anim id est laborum.",
    imgSrc: "assets/img/reviewer-1.jpg",
    name: "Selena Brook",
    label: "Vivaco Group",
  },
  {
    id: 4,
    testimonial:
      "Duis aute irure dolor in reprehenderit in voluptate velit cillum dolore eu fugiat nulla pariatur. Excepteur sint proident, culpa qui officia deserunt mollit anim id est laborum.",
    imgSrc: "assets/img/reviewer-2.jpg",
    name: "Nicolas Marko",
    label: "Chief Operating Officer",
  },
];
const faqItems = [
  {
    id: 1,
    title: "Simple process for workflow?",
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over words, combined with a handful of model structures, to generate Lorem Ipsum which looks reasonable.",
  },
  {
    id: 2,
    title: "Unique brand identity and strategy",
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over words, combined with a handful of model structures, to generate Lorem Ipsum which looks reasonable.",
  },
  {
    id: 3,
    title: "Tailor-made digital products",
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over words, combined with a handful of model structures, to generate Lorem Ipsum which looks reasonable.",
  },
];

const portfolioData = [
  {
    id: 1,
    category: "branding-app",
    imgSrc: "/assets/img/portfolio-1.jpg",
    mainSrc: "/assets/img/portfolio-main-1.jpg",
    title: "Secure",
    slug: "ux-consulting",
  },
  {
    id: 2,
    category: "landing-page",
    imgSrc: "/assets/img/portfolio-3.jpg",
    mainSrc: "/assets/img/portfolio-main-3.jpg",
    title: "Figma Design",
    slug: "figma-design",
  },
  {
    id: 3,
    category: "branding-app",
    imgSrc: "/assets/img/portfolio-5.jpg",
    mainSrc: "/assets/img/portfolio-main-5.jpg",
    title: "Branding Revamp",
    slug: "branding-revamp",
  },
  {
    id: 4,
    category: "ios-app",
    imgSrc: "/assets/img/portfolio-2.jpg",
    mainSrc: "/assets/img/portfolio-main-2.jpg",
    title: "iOS App Development",
    slug: "ios-app-development",
  },
  {
    id: 5,
    category: "website",
    imgSrc: "/assets/img/portfolio-4.jpg",
    mainSrc: "/assets/img/portfolio-main-4.jpg",
    title: "Website Enhancement",
    slug: "website-enhancement",
  },
  {
    id: 6,
    category: "ios-app",
    imgSrc: "/assets/img/portfolio-6.jpg",
    mainSrc: "/assets/img/portfolio-main-6.jpg",
    title: "Mobile App UI/UX",
    slug: "mobile-app-ui-ux",
  },
];

const blogData = [
  {
    id: 1,
    category: "Business",
    date: "January 03, 2024",
    title: "How to make a website using WordPress",
    slug: "how-to-make-a-website-using-wordpress",
    imgSrc: "/assets/img/blog-1.jpg",
    tags: ["Businesses", "Audio Post", "Digital"],
  },
  {
    id: 2,
    category: "Design",
    date: "February 15, 2024",
    title: "Exploring the Power of ES6 Features",
    slug: "exploring-the-power-of-es6-features",
    imgSrc: "/assets/img/blog-2.jpg",
    tags: ["Gallery Post", "Marketing", "Agency"],
  },
  {
    id: 3,
    category: "Creative",
    date: "March 07, 2024",
    title: "Mastering CSS Flexbox and Grid Layouts",
    slug: "mastering-css-flexbox-and-grid-layouts",
    imgSrc: "/assets/img/blog-3.jpg",
    tags: ["Web Development", "Graphic", "Audio Post"],
  },
  {
    id: 4,
    category: "Creative",
    date: "April 21, 2024",
    title: "Building Interactive User Interfaces with React",
    slug: "building-interactive-user-interfaces-with-react",
    imgSrc: "/assets/img/blog-4.jpg",
    tags: ["Digital", "Businesses", "Marketing"],
  },
  {
    id: 5,
    category: "Marketing",
    date: "May 12, 2024",
    title: "Principles of Effective Web Design",
    slug: "principles-of-effective-web-design",
    imgSrc: "/assets/img/blog-5.jpg",
    tags: ["Gallery Post", "Web Development", "Graphic"],
  },
  {
    id: 6,
    category: "Lifestyle",
    date: "June 05, 2024",
    title: "Introduction to Server-Side JavaScript",
    slug: "introduction-to-server-side-javascript",
    imgSrc: "/assets/img/blog-6.jpg",
    tags: ["Audio Post", "Marketing", "Businesses"],
  },
  {
    id: 7,
    category: "Lifestyle",
    date: "July 18, 2024",
    title: "Deep Dive into HTML5 Features",
    slug: "deep-dive-into-html5-features",
    imgSrc: "/assets/img/blog-7.jpg",
    tags: ["Web Development", "Gallery Post", "Marketing"],
  },
  {
    id: 8,
    category: "Marketing",
    date: "August 09, 2024",
    title: "Responsive Layouts for Modern Websites",
    slug: "responsive-layouts-for-modern-websites",
    imgSrc: "/assets/img/blog-8.jpg",
    tags: ["Graphic", "Audio Post", "Web Development"],
  },
  {
    id: 9,
    category: "Design",
    date: "September 30, 2024",
    title: "Introduction to GraphQL",
    slug: "introduction-to-graphql",
    imgSrc: "/assets/img/blog-9.jpg",
    tags: ["Businesses", "Marketing", "Digital"],
  },
  {
    id: 10,
    category: "Marketing",
    date: "October 12, 2024",
    title: "Best Practices for Web Security",
    slug: "best-practices-for-web-security",
    imgSrc: "/assets/img/blog-10.jpg",
    tags: ["Gallery Post", "Web Development", "Audio Post"],
  },
  {
    id: 11,
    category: "Design",
    date: "November 23, 2024",
    title: "Getting Started with Vue.js",
    slug: "getting-started-with-vue-js",
    imgSrc: "/assets/img/blog-11.jpg",
    tags: ["Graphic", "Digital", "Businesses"],
  },
  {
    id: 12,
    category: "Creative",
    date: "December 15, 2024",
    title: "Comparing Popular Frontend Frameworks",
    slug: "comparing-popular-frontend-frameworks",
    imgSrc: "/assets/img/blog-12.jpg",
    tags: ["Marketing", "Web Development", "Graphic"],
  },
  {
    id: 13,
    category: "Business",
    date: "January 08, 2025",
    title: "Enhancing CSS with SASS",
    slug: "enhancing-css-with-sass",
    imgSrc: "/assets/img/blog-13.jpg",
    tags: ["Audio Post", "Gallery Post", "Marketing"],
  },
  {
    id: 14,
    category: "Business",
    date: "February 02, 2025",
    title: "Web Accessibility for All Users",
    slug: "web-accessibility-for-all-users",
    imgSrc: "/assets/img/blog-14.jpg",
    tags: ["Businesses", "Digital", "Gallery Post"],
  },
  {
    id: 15,
    category: "Creative",
    date: "March 17, 2025",
    title: "Optimizing Web Performance",
    slug: "optimizing-web-performance",
    imgSrc: "/assets/img/blog-15.jpg",
    tags: ["Web Development", "Graphic", "Audio Post"],
  },
];

const skillData = [
  {
    id: 1,
    img: "assets/img/figma.png",
    title: "Figma",
    mastery: 60,
  },
  {
    id: 2,
    img: "assets/img/wordpress.png",
    title: "Wordpress",
    mastery: 95,
  },
  {
    id: 3,
    img: "assets/img/html.jpg",
    title: "HTML",
    mastery: 85,
  },
  {
    id: 4,
    img: "assets/img/bootstrap.png",
    title: "Bootstrap",
    mastery: 95,
  },
];
const educationData = [
  {
    id: 1,
    timespan: "2006 - 2009",
    degree: "Bachelors in Computer Application",
    school: "IASE University, Rajasthan, India",
  },
  {
    id: 2,
    timespan: "2004 - 2006",
    degree: "Intermediate - Computer Science",
    school: "JNV Sultanpur, UP, India",
  },
  {
    id: 3,
    timespan: "2002 - 2004",
    degree: "High School",
    school: "JNV Raebareli, UP, India",
  },
  {
    id: 4,
    timespan: "2014 - 2014",
    degree: "Making Sense of Data Course",
    school: "Google India",
  },
  {
    id: 5,
    timespan: "2020 - 2022",
    degree: "AWS Certified Cloud Practitioner",
    school: "Amazon Web Services",
  },
  {
    id: 6,
    timespan: "2021 - 2021",
    degree: "Certfied Information Assurance",
    school: "DIAT (DRDO), India",
  },
];

const experienceData = [
  {
    id: 1,
    timespan: "2023 - ....",
    position: "VP Technology",
    company: "The Lending Tree, Dubai",
  },
  {
    id: 2,
    timespan: "2018 - 2023",
    position: "Head Of IT & Security",
    company: "DSSC Tech LTD. Dubai",
  },
  {
    id: 3,
    timespan: "2017 - 2018",
    position: "Sr. DevOps Engineer",
    company: "Econet Vision Limited, Dubai",
  },
  {
    id: 4,
    timespan: "2014 - 2017",
    position: "Sr. Data Engineer / DevOps",
    company: "Ogilvy & Mather - neo@Ogilvy, Gurugram, India",
  },
  {
    id: 5,
    timespan: "2012 - 2014",
    position: "Web Manager",
    company: "Smiles Group, Gurugram, India",
  },
  {
    id: 6,
    timespan: "2012 - 2012",
    position: "Sr. System Admin / DevOps",
    company: "Tech Freaks Pvt. LTD, Lucknow, India",
  },
  {
    id: 7,
    timespan: "2011 - 2012",
    position: "System Admin / DevOps",
    company: "Elite Business Media Pvt. Ltd., Lucknow, India",
  },
];

const pricingData = [
  {
    iconSrc: "assets/img/pricing-1.png",
    title: "Basic Plan",
    price: "$20",
    features: ["Web Development", "Mobile Development", "Advetising"],
    disabledFeatures: ["Graphic design", "Portfolio management"],
    btnText: "Start My portfolio Now",
  },
  {
    iconSrc: "assets/img/pricing-2.png",
    title: "Ultra Plan",
    price: "$30",
    features: [
      "Web Development",
      "Mobile Development",
      "Advetising",
      "Graphic design",
    ],
    disabledFeatures: ["Portfolio management"],
    tag: "best plan",
    btnText: "Start My portfolio Now",
  },
  {
    iconSrc: "assets/img/pricing-3.png",
    title: "Gold Plan",
    price: "$60",
    features: [
      "Web Development",
      "Mobile Development",
      "Advetising",
      "Graphic design",
      "Portfolio management",
    ],
    btnText: "Start My portfolio Now",
  },
];

export {
  serviceData,
  testimonialData,
  faqItems,
  portfolioData,
  blogData,
  skillData,
  educationData,
  experienceData,
  pricingData,
};
