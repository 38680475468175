import BannerSection from "../component/banner/BannerSection";
import AboutSection from "../component/about/AboutSection";
import ExperienceSection from "../component/experience/ExperienceSection";

import ContactSection from "../component/contact/ContactSection";

const HomePage = () => {
  return (
    <main>
      <BannerSection />
      <AboutSection style="" showHeader={true} />
      <ExperienceSection style="nk-experiences" showHeader={true} />
      <ContactSection style="" />
    </main>
  );
};

export default HomePage;
